import { post, get } from '@/utils/request'

export default {
  sendEmail(body) {
    return post(`/common/email/exist`, body)
  },
  jsConfig(url) {
    return get(`/rest/weixin/js-config`, { url })
  },
}
